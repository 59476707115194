import { z } from "zod";
import { UserAccountType } from "../accounts";
import { bankAccountSchema } from "../bank-accounts/schemas/bankAccount.schema";
import { schemes } from "../schema-validation";
import {
  EWorkerInformationSection,
  type TWorkerInformationSection,
} from "./EWorkerInformationSection";

export type TShareableLinkSectionTitleForEmail =
  | "account login information"
  | "bank information"
  | "employee survey"
  | "paperwork"
  | "paycard enrollment"
  | "profile information";

export const shareableLinkSectionToEmailTitleMap: Record<
  TWorkerInformationSection,
  TShareableLinkSectionTitleForEmail
> = {
  [EWorkerInformationSection.ACCOUNT_INFO]: "account login information",
  [EWorkerInformationSection.SURVEY]: "employee survey",
  [EWorkerInformationSection.BANK_INFO]: "bank information",
  [EWorkerInformationSection.CUSTOM_PAPERWORK]: "paperwork",
  [EWorkerInformationSection.PAYCARD]: "paycard enrollment",
  [EWorkerInformationSection.PROFILE_INFO]: "profile information",
};

export const workerInformationSectionSchema = z.object({
  name: z.nativeEnum(EWorkerInformationSection),
});

export const shareableLinksTokenPayloadSchema = z.object({
  companyID: schemes.companyID(),
  email: schemes.email(),
  formTemplateIDs: z.array(z.string()).optional(),
  isShareableLink: z.literal(true),
  sections: z.array(workerInformationSectionSchema),
  testMode: z.boolean(),
  whiteLabelID: z.string(),
  workerID: schemes.mongoObjectId(),
  workerType: z.union([z.literal("contractor"), z.literal("worker")]),
});

export const workerInformationCompanySchema = z.object({
  company_id: schemes.companyID(),
  business_name: z.string(),
});

export const workerInformationFormsSchema = z.object({
  formID: z.string(),
  formTemplateID: z.string().optional(),
  label: z.string(),
});

export const workerInformationWorkerSchema = z.object({
  _id: schemes.mongoObjectId(),
  address: z.string().optional(),
  address_line2: z.string().optional(),
  city: z.string().optional(),
  first_name: z.string().optional(),
  hash: z.string().optional(),
  last_name: z.string().optional(),
  middle_initial: z.string().optional(),
  dob: z.string().optional(),
  phone_number: z.string().optional(),
  ssn: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  paycard_enabled: z.boolean().optional(),
  user_type: z.nativeEnum(UserAccountType).optional(),
});

export const workerInformationPayloadSchema = z.object({
  company: workerInformationCompanySchema,
  bankAccounts: z.array(bankAccountSchema).nullish(),
  forms: z.array(workerInformationFormsSchema).nullish(),
  logoURL: z.string().nullish(),
  worker: workerInformationWorkerSchema,
  tokenPayload: shareableLinksTokenPayloadSchema,
});

export type TShareableLinksSection = z.infer<
  typeof workerInformationSectionSchema
>;
export type TShareableLinksTokenPayload = z.infer<
  typeof shareableLinksTokenPayloadSchema
>;
export type TWorkerInformationPayload = z.infer<
  typeof workerInformationPayloadSchema
>;
