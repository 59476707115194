import { z } from "zod";
import { EWorkerVerificationIDType, UserAccountType } from "../accounts";

const getRequiredErrMsgByField = (field: string) => {
  const splitInTwo = field.replace("_", " ");
  return splitInTwo[0].toUpperCase() + splitInTwo.slice(1) + " is required.";
};

export const updateUserOnboardingSettingsSchema = z
  .object({
    stages: z.object({
      bank_info: z.boolean(),
      contractor_acct: z.boolean(),
      employee_acct: z.boolean(),
      i9: z.boolean(),
      id_scan: z.boolean(),
      paperwork: z.boolean(),
      payment_method_required: z.boolean(),
      profile_info: z.boolean(),
      paycard: z.boolean(),
      health_insurance: z.boolean(),
      survey: z.boolean(),
      type: z.nativeEnum(UserAccountType).optional(),
    }),
    idScanDocuments: z.object({
      [EWorkerVerificationIDType.GOVERNMENT_PHOTO_ID]: z.boolean(),
      [EWorkerVerificationIDType.PASSPORT]: z.boolean(),
      [EWorkerVerificationIDType.DRIVERS_LICENSE]: z.boolean(),
      [EWorkerVerificationIDType.SOCIAL_SECURITY_CARD]: z.boolean(),
      [EWorkerVerificationIDType.SOCIAL_SECURITY_CARD_BACK]: z.boolean(),
      [EWorkerVerificationIDType.OTHER]: z.boolean(),
      [EWorkerVerificationIDType.OTHER_BACK]: z.boolean(),
      [EWorkerVerificationIDType.I9_REQUIREMENTS]: z.boolean(),
    }),
  })
  .optional();

export const updateUserInfoSchema = z.object({
  first_name: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("first_name") }),
  last_name: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("last_name") }),
  job_title: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("job_title") }),
  business_name: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("business_name") }),
  email: z.string(), // un-editable field
  business_ein: z.string(), // un-editable field
  business_address: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("business_address") }),
  business_city: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("business_city") }),
  business_state: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("business_state") }),
  business_zip: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("business_zip") })
    .length(5, { message: "Zip code must be 5 digits" }),
  business_phone: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("business_phone") }),
  isMailingAddressDifferent: z.boolean(),
  mail_address: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("mail_address") })
    .optional()
    .or(z.literal("")),
  mail_city: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("mail_city") })
    .optional()
    .or(z.literal("")),
  mail_state: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("mail_state") })
    .optional()
    .or(z.literal("")),
  mail_zip: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("mail_zip") })
    .length(5, { message: "Zip code must be 5 digits" })
    .optional()
    .or(z.literal("")),
  legal_structure: z.string(),
  ownership_percentage: z.coerce.number(),
  dob: z.string(),
  address: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("address") }),
  address_line2: z.string().optional(),
  city: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("city") }),
  state: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("state") }),
  zip: z
    .string()
    .trim()
    .min(1, { message: getRequiredErrMsgByField("zip") })
    .length(5, { message: "Zip code must be 5 digits" }),
  onboarding_settings: updateUserOnboardingSettingsSchema,
  file_title: z.string().optional(),
});

export type TUpdateUserInfoInput = z.infer<typeof updateUserInfoSchema>;

export interface IUpdateUserInfoParams extends TUpdateUserInfoInput {
  companyID: string;
}

export interface IUpdateUserInfoData {
  success: boolean;
  data: boolean;
}
