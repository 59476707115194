import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IGetOnboardingAuthLinkResponseBody } from "./getOnboardingAuthLink.jsonschema";

export const getOnboardingAuthLink = (axios: IAxiosInstance) => {
  return async (
    params: ISchema["#/definitions/IGetOnboardingAuthLinkArgs"]
  ) => {
    const {
      profileInfo,
      createAcct,
      idScan,
      i9,
      paperwork,
      bankInfo,
      paymentMethod,
      companyID,
      workerID,
      userType,
      idScanDocuments,
      isKYC,
      paycard,
      survey,
    } = params;

    const requestParams: ISchema["#/definitions/IGetOnboardingAuthLinkQueryParams"] =
      {
        workerID,
        userType: userType === "worker" ? "Worker" : "contractor", // La-Forge expects capital "Worker"
        paperwork,
        companyID,
        employee_acct: createAcct,
        contractor_acct: createAcct,
        paycard,
        survey,
        id_scan: idScan,
        i9: i9,
        profile_info: profileInfo,
        profile: profileInfo,
        bank_info: bankInfo,
        payment_method_required: paymentMethod,
        idScanDocuments,
        isKYC,
      };

    const response =
      await axios.noRetry.get<IGetOnboardingAuthLinkResponseBody>(
        `/api/getOnboardingAuthLink`,
        { params: requestParams }
      );

    const resData = response.data;

    if (resData.success) {
      return {
        link: resData.data,
        accountStageDisabled: resData.accountStageDisabled,
      };
    }

    throw new Error("Something went wrong");
  };
};
