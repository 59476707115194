import { z } from "zod";
import { productDefinitionSchema } from "../../products/schemas/productDefinitionSchema";
import { uuid } from "../../schema-validation/schemes";

export const taskDefinitionTemplateSchema = z.object({
  taskDefinitionTemplateId: uuid(),
  productDefinitionId: uuid(),
  requirements: z.object({
    employee: z.array(
      z.object({
        source: z.string(),
        category: z.enum(["profile", "userAccount", "bankAccount", "w4"]),
        fields: z.array(z.string()),
      })
    ),
    company: z
      .array(
        z.object({
          source: z.string(),
          fields: z.array(z.string()),
        })
      )
      .default([]),
  }),
});

export type ZTaskDefinitionTemplate = z.infer<
  typeof taskDefinitionTemplateSchema
>;

export const taskDefinitionTemplateRefsPopulatedSchema =
  taskDefinitionTemplateSchema.extend({
    productDefinition: productDefinitionSchema.pick({
      product: true,
      description: true,
      productType: true,
    }),
  });

export type ZTaskDefinitionTemplateRefsPopulated = z.infer<
  typeof taskDefinitionTemplateRefsPopulatedSchema
>;
