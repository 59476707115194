import * as DateUtils from "../dates/utils";
import * as MoneyUtils from "../money/utils";
import { EDisbursementMethod } from "../workers-shared";

export const getDisbursementLabel = (method: EDisbursementMethod) => {
  switch (method) {
    case EDisbursementMethod.DOWNLOAD_CHECK:
      return "Download check";
    case EDisbursementMethod.PHYSICAL_CHECK:
      return "Physical check";
    case EDisbursementMethod.PREPAID:
      return "Prepaid";
    case EDisbursementMethod.PAYCARD:
      return "Paycard";
    default:
      return "Direct deposit";
  }
};

export const getDisbursementCheckInfoLabel = (method?: EDisbursementMethod) => {
  switch (method) {
    case EDisbursementMethod.DOWNLOAD_CHECK:
      return "Download check";
    case EDisbursementMethod.PHYSICAL_CHECK:
      return "Physical check";
    case EDisbursementMethod.PREPAID:
      return "Prepaid";
    case EDisbursementMethod.PAYCARD:
      return "Paycard";
    default:
      return "Direct deposit";
  }
};

export const getReportingPeriod = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  if (!startDate && !endDate) return null;

  return `${DateUtils.getUSFormattedDate(
    startDate
  )} - ${DateUtils.getUSFormattedDate(endDate)}`;
};

export const getShiftWageLabel = (wage: number | undefined) =>
  `${MoneyUtils.getCentToDollarWSpace(wage)}/hour`;

export const getShiftHoursLabel = (hours: number | undefined) =>
  `${hours?.toFixed(2) ?? "__"} hours`;
