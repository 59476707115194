import { z } from "zod";

import { companyOnboardingEvent } from "./companyOnboardingEvent";
import { companyTaskDefinitionEvent } from "./companyTaskDefinitionEvent";
import { companyWorkLocationEvent } from "./companyWorkLocationEvent";
import { contractorEvent } from "./contractorEvent";
import { employeeEvent } from "./employeeEvent";
import { transactionEvent } from "./transactionEvent";

export const eventsUnion = z.discriminatedUnion("source", [
  employeeEvent,
  contractorEvent,
  companyTaskDefinitionEvent,
  companyOnboardingEvent,
  companyWorkLocationEvent,
  transactionEvent,
]);

export type EventsUnion = z.infer<typeof eventsUnion>;
